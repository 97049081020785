import { fetch } from '@/api/fetch';
import Response from '../../utils/response';

const state = {
  entries: [],
  indicators: [],
  indicatorsEntries: [],
  countResults: {
    totalCount: 0,
    totalSafe: 0,
    totalDiscarded: 0,
  },
  total: 0,
  loading: false,
  disabledButtonReports: false,
  selectFilter: '',
  selectEntryIntelligence: 'all',
  entrySearch: '',
};

const mutations = {
  SET_ENTRIES(state, data) {
    state.entries = data;
  },
  SET_TOTAL(state, data) {
    state.total = data;
  },
  SET_REMOVE_ENTRIES(state) {
    state.entries = [];
  },
  SET_INDICATORS(state, data) {
    state.indicators = data;
  },
  SET_INDICATORS_ENTRIES(state, data) {
    state.indicatorsEntries = data;
  },
  SET_COUNT_RESULTS(state, data) {
    state.countResults = data;
  },
  SET_DISABLED_BUTTON_REPORTS(state, data) {
    state.disabledButtonReports = data;
  },
  SET_SELECT_FILTER(state, filter) {
    state.selectFilter = filter;
  },
  SET_SELECT_ENTRY_INTELLIGENCE(state, filter) {
    state.selectEntryIntelligence = filter;
  },
  SET_ENTRY_SEARCH(state, search) {
    state.entrySearch = search;
  },
};

const actions = {
  GET_INDICATORS({ commit }, data) {
    commit('SET_INDICATORS', data);
  },
  GET_INDICATORS_ENTRIES({ commit }, data) {
    commit('SET_INDICATORS_ENTRIES', data);
  },
  GET_COUNT_RESULTS({ commit }, data) {
    commit('SET_COUNT_RESULTS', data);
  },
  GET_DISABLED_BUTTON_REPORTS({ commit }, data) {
    commit('SET_DISABLED_BUTTON_REPORTS', data);
  },
  GET_ENTRIES({ commit, state }, _data) {
    state.loading = true;
    fetch('get', `/api/get-entries/${_data.env}/${_data.programId}?excel=false&page=${_data.page}&size=${_data.size}&search=${_data.search}`).then((response) => {
      const { statusCode, data } = response;
      const { count } = data;

      commit('SET_TOTAL', count);

      const entries = data.content.map((item) => {
        (item.rules === 1) ? item.rules = 'Yes' : item.rules = 'No';
        (item.updates === 1) ? item.updates = 'Yes' : item.updates = 'No';
        return item;
      });

      if (statusCode === Response.HTTP_OK) {
        commit('SET_ENTRIES', entries);
        state.loading = false;
      } else {
        commit('SET_REMOVE_ENTRIES');
        state.loading = false;
      }
    }).catch(() => {
      state.loading = false;
    });
  },
  GET_SELECT_FILTER({ commit }, data) {
    commit('SET_SELECT_FILTER', data);
  },
  GET_SELECT_ENTRY_INTELLIGENCE({ commit }, data) {
    commit('SET_SELECT_ENTRY_INTELLIGENCE', data);
  },
  GET_ENTRY_SEARCH({ commit }, data) {
    commit('SET_ENTRY_SEARCH', data);
  },
};

const getters = {
  indicators: (state) => {
    return state.indicators;
  },
  indicatorsEntries: (state) => {
    return state.indicators;
  },
  entries: (state) => {
    return state.entries;
  },
  countResults: (state) => {
    return state.countResults;
  },
  disabledButtonReports: (state) => {
    return state.disabledButtonReports;
  },
  total: (state) => {
    return state.total;
  },
  selectFilter: (state) => state.selectFilter,
  selectEntryIntelligence: (state) => state.selectEntryIntelligence,
  entrySearch: (state) => state.entrySearch,
};

export default {
  namespaced: true, state, mutations, actions, getters
};
