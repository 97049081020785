import { http } from '@/api/fetch';
import store from '../store';
// import useNavigationEntries from './use-navigation-entries';

// esta funcionalidad es para ajustar el bug cuando se ingresa a la informacion de una campaña
// desde un link directo compartido
const useExploreFromDirectLink = async (programId) => {
  // alert('useExploreFromDirectLink');

  // obtener informacion dle programa
  const programData = await http('get', `/api/get-program/${programId}`);
  const programRules = await http('get', `/api/get-rules/${programId}`);
  const { data } = programData;

  store.dispatch('utilities/GET_PATH', '/entries');
  if (data.default_view === 'moderation') store.dispatch('utilities/GET_PATH', '/moderation-entries');
  if (data.default_view === 'instant_win') store.dispatch('utilities/GET_PATH', '/instantwin-entries');
  if (data.default_view === 'approve_entries') store.dispatch('utilities/GET_PATH', '/approve-entries');
  if (data.default_view === 'scratch_win') store.dispatch('utilities/GET_PATH', '/scratchwin-entries');

  const { brandData: brand } = data;
  const startFormat = programRules.data.start_date_format;
  const endFormat = programRules.data.end_date_format;

  // configuramos la informacion como la necesitamos
  const newData = {
    _id: data._id,
    title: data.title,
    brand: {
      _id: brand._id,
      title: brand.title,
      image: brand.image !== '' ? brand.image : '@/assets/img/cms/placeholder-brand.png',
    },
    rules: data.rules,
    active: data.active,
    archived: data.archived,
    type: data.type,
    db_connection_string: '',
    default_view: data.default_view,
    miloreportid: data.miloreportid,
    url_test: data.url_test,
    url_prod: data.url_prod,
    test_env_mode: data.test_env_mode,
    slackurl: data.slackurl,
    api_video_token: data.api_video_token,
    upload_token_test: data.upload_token_test,
    upload_token_prod: data.upload_token_prod,
    plugins: data.pluginsWithData,
    createdAt: data.createdAt,
    slug: data.slug,
    status: data.status,
    dbserver: data.dbserver
  };

  // ahora seteamos los stroe para configurar los datos de la campaña en las vistas.
  store.dispatch('utilities/GET_PAGE', 1);
  store.dispatch('campaigns/GET_ID', newData._id);
  store.state.campaigns.miloreportid = '';
  store.state.campaigns.plug_ins = [];
  store.state.moderation.isQualified = false;
  const { plugins, status } = newData;
  const plugArray = plugins.filter( (plg) => plg.active ).map( (plg2) => plg2.plugin_key );
  store.state.campaigns.plug_ins = plugArray;
  store.state.utilities.viewEntry = 'other';
  store.state.utilities.sortTableColumn = '';
  store.state.utilities.auxiliaryCampaignStatus = status;
  store.dispatch('campaigns/GET_CAMPAINN_STATUS', status);
  if ( plugArray.includes('fraud_detection') ) store.state.utilities.viewEntry = 'fraud_detection';
  store.state.campaigns.miloreportid = '';
  if ( newData.miloreportid ) store.state.campaigns.miloreportid = newData.miloreportid;
  store.state.campaigns.url_test = '';
  store.state.campaigns.url_prod = '';
  if ( newData.url_test ) store.state.campaigns.url_test = newData.url_test;
  if ( newData.url_prod ) store.state.campaigns.url_prod = newData.url_prod;
  store.dispatch('campaigns/GET_START', startFormat);
  store.dispatch('campaigns/GET_ENDS', endFormat);
  store.dispatch('campaigns/GET_CAMPAINN_NAME', newData.title);
  store.dispatch('campaigns/GET_TYPE_CAMPAINNS', newData.type);
  store.dispatch('brands/GET_IMAGE_BRAND', newData.brand.image);
};

export default useExploreFromDirectLink;
