/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
const state = {
  activeIndex: '1',
};

const mutations = {
  SET_ACTIVE_INDEX(state, key) {
    state.activeIndex = key;
  },
  SET_REMOVE_ACTIVE_INDEX(state) {
    state.activeIndex = '1';
  },
};

const actions = {
  GET_ACTIVE_INDEX({ commit }, data) {
    commit('SET_ACTIVE_INDEX', data);
  },
};

const getters = {
  activeIndex: (state) => {
    return state.activeIndex;
  },
};

export default {
  namespaced: true, state, mutations, actions, getters
};
