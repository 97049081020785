import { fetch } from '@/api/fetch';
import Response from '../../utils/response';

const state = {
  moderationsFields: [],
  moderationsColumns: [],
  scoreCardRating: [],
  count: 0,
  pending: 0,
  rated: 0,
  disqualified: 0,
  page: 1,
  totalPages: 0,
  displaying: 10,
  overall: 0,
  myself: 0,
  env: 'test',
  myratingfilter: true,
  sort: '',
  loadingTable: false,
  isJudge: null,
  scaleType: '',
};

const mutations = {
  SET_MODERATIONS_FIELDS(state, data) {
    state.moderationsFields = data;
  },
  SET_SCALE_TYPE(state, data) {
    state.scaleType = data;
  },
  // eslint-disable-next-line no-unused-vars
  SET_REMOVE_MODERATIONS_FIELDS(state, data) {
    state.moderationsFields = [];
  },
  SET_MODERATIONS_COLUMNS(state, data) {
    state.moderationsColumns = data;
  },
  // eslint-disable-next-line no-unused-vars
  SET_REMOVE_MODERATIONS_COLUMNS(state, data) {
    state.moderationsColumns = [];
  },
  SET_COUNT(state, data) {
    state.count = data;
  },
  SET_PENDING(state, data) {
    state.pending = data;
  },
  SET_RATED(state, data) {
    state.rated = data;
  },
  SET_DISQUALIFIED(state, data) {
    state.disqualified = data;
  },
  SET_OVERALL(state, data) {
    state.overall = data;
  },
  SET_MY_SELF(state, data) {
    state.myself = data;
  },
  SET_PAGE(state, data) {
    state.page = data;
  },
  SET_REMOVE_PAGE(state) {
    state.page = 1;
  },
  SET_TOTAL_PAGES(state, data) {
    state.totalPages = data;
  },
  SET_DISPLAYING(state, data) {
    state.displaying = data;
  },
  SET_REMOVE_DISPLAYING(state) {
    state.displaying = 10;
  },
  SET_ENV(state, data) {
    state.env = data;
  },
  SET_REMOVE_ENV(state) {
    state.env = 'test';
  },
  SET_SORT(state, data) {
    state.sort = data;
  },
  SET_MY_RATING_FILTER(state, data) {
    state.myratingfilter = data;
  },
  SET_SCORE_CARD_RATING(state, data) {
    state.scoreCardRating = data;
  },
  SET_REMOVE_SCORE_CARD_RATING(state) {
    state.scoreCardRating = [];
  },
  SET_IS_JUDGE(state, data) {
    state.isJudge = data;
  },
};

const actions = {
  async GET_MODERATIONS_FIELDS({ commit, state }, _data) {
    const {
      page, size, search, env, programId, sort, allrating
    } = _data;

    state.loadingTable = true;
    const [fieldsModeration, getModerations] = await Promise.all([
      fetch('get', `api/get-fields-moderation-v2/${programId}`),
      fetch('get', `/api/get-moderations/${env}/${programId}?excel=false&page=${page}&size=${size}&sort=${sort}&allrating=${allrating}&search=${search}`),
    ]);

    const { statusCode, data } = fieldsModeration;
    const {
      count,
      total_pages,
      content, pending, scores, scores_overall, scores_myself, isJudge, scaleType
    } = getModerations.data;

    const disqualified = getModerations.data.disqualified || 0;

    if (statusCode === Response.HTTP_OK) {
      const arrayColumns = data.findIndex((el) => el.label === 'rating');
      if (arrayColumns >= 0) {
        data.splice(arrayColumns, 1);
      }
      const columns = data.map((f) => {
        // eslint-disable-next-line no-unused-vars
        const { name, field_type: type, field_width: width } = f;

        const prop = f.name;
        const label = f.name.toUpperCase();
        // const label = capitalize(f.name.replace('_', ' '));
        // const type = f.field_type;

        const align = 'left';

        return {
          prop, label, align, width
        };
      });

      columns.push({
        prop: 'createdAt',
        label: 'createdAt'.toUpperCase(),
        align: 'left',
        type: 'datetime',
        width: '180'
      });

      columns.unshift({
        prop: 'id',
        label: 'Id'.toUpperCase(),
        align: 'center',
        width: '75'
      });

      // columns.unshift({
      //   prop: 'id',
      //   label: 'Id'.toUpperCase(),
      //   align: 'center',
      //   width: '75'
      // }, {
      //   prop: 'probabilityRating',
      //   label: 'probabilityRating'.toUpperCase(),
      //   align: 'center',
      //   headerAlign: 'center',
      //   width: '200',
      //   type: 'rating',
      // });
      // eslint-disable-next-line no-alert
      // alert( total_pages );

      commit('SET_COUNT', count);
      commit('SET_PENDING', pending);
      commit('SET_OVERALL', scores_overall);
      commit('SET_MY_SELF', scores_myself);
      commit('SET_RATED', scores);
      commit('SET_DISQUALIFIED', disqualified);
      commit('SET_TOTAL_PAGES', total_pages);
      commit('SET_IS_JUDGE', isJudge);

      commit('SET_MODERATIONS_COLUMNS', columns);
      commit('SET_MODERATIONS_FIELDS', content);
      commit('SET_SCALE_TYPE', scaleType);
      state.loadingTable = false;
    }

    if ( _data.callback !== undefined && typeof _data.callback === 'function' ) {
      _data.callback();
    }
  },
  GET_SCORE_CARD_RATING({ commit }, data) {
    commit('SET_SCORE_CARD_RATING', data);
  },
  GET_PAGE({ commit }, data) {
    commit('SET_PAGE', data);
  },
  GET_DISPLAYING({ commit }, data) {
    commit('SET_DISPLAYING', data);
  },
  GET_ENV({ commit }, data) {
    commit('SET_ENV', data);
  },
  GET_SORT({ commit }, data) {
    commit('SET_SORT', data);
  },
  GET_MY_RATING_FILTER({ commit }, data) {
    commit('SET_MY_RATING_FILTER', data);
  },
};

const getters = {
  ScaleType: (state) => {
    return state.scaleType;
  },
  moderationsFields: (state) => {
    return state.moderationsFields;
  },
  moderationsColumns: (state) => {
    return state.moderationsColumns;
  },
  count: (state) => {
    return state.count;
  },
  pending: (state) => {
    return state.pending;
  },
  rated: (state) => {
    return state.rated;
  },
  disqualified: (state) => {
    return state.disqualified;
  },
  page: (state) => {
    return state.page;
  },
  totalPages: (state) => {
    return state.totalPages;
  },
  displaying: (state) => {
    return state.displaying;
  },
  env: (state) => {
    return state.env;
  },
  sort: (state) => {
    return state.sort;
  },
  myratingfilter: (state) => {
    return state.myratingfilter;
  },
  overall: (state) => {
    return state.overall;
  },
  myself: (state) => {
    return state.myself;
  },
  scoreCardRating: (state) => {
    return state.scoreCardRating;
  },
  isJudge: (state) => {
    return state.isJudge;
  },
};

export default {
  namespaced: true, state, mutations, actions, getters
};
