import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import useExploreFromDirectLink from '../composables/use-explore-from-direct-link';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
  },
  {
    path: '/admin/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/auth/Auth2.vue'),
  },
  {
    path: '/login',
    redirect: '/admin/login',
  },
  {
    path: '/admin/identity-check',
    name: '2Fa',
    component: () => import(/* webpackChunkName: "2Fa" */ '../views/2fa/TwoStepVerification.vue'),
  },
  {
    path: '/admin',
    name: 'Default',
    component: () => import(/* webpackChunkName: "Default" */ '../layouts/Default.vue'),
    redirect: '/admin/campaigns',
    children: [
      {
        path: '/admin/campaigns',
        name: 'Campaigns',
        meta: { main: true },
        component: () => import(/* webpackChunkName: "Campaigns" */ '../views/campaigns/Campaigns.vue'),
      },
      {
        path: '/admin/brands',
        name: 'Brands',
        meta: { main: true },
        component: () => import(/* webpackChunkName: "Brands" */ '../views/brand/Brand.vue'),
        beforeEnter: (to, from, next) => {
          const { isSuperAdmin } = store.state.auth.user;
          if (!isSuperAdmin) {
            next({ name: 'Campaigns' });
          }
          next();
        }
      },
      {
        path: '/admin/users',
        name: 'Users',
        meta: { main: true },
        component: () => import(/* webpackChunkName: "Users" */ '../views/users/Users.vue'),
        beforeEnter: (to, from, next) => {
          const { isSuperAdmin } = store.state.auth.user;
          if (!isSuperAdmin) {
            next({ name: 'Campaigns' });
          }
          next();
        }
      },
      {
        path: '/admin/users2',
        name: 'Users2',
        meta: { main: true },
        component: () => import(/* webpackChunkName: "Users2" */ '../views/users/Users2.vue'),
        beforeEnter: (to, from, next) => {
          const { isSuperAdmin } = store.state.auth.user;
          if (!isSuperAdmin) {
            next({ name: 'Campaigns' });
          }
          next();
        }
      },
      {
        path: '/admin/users/create',
        name: 'CreateUser',
        meta: { main: true },
        component: () => import(/* webpackChunkName: "CreateUser" */ '../views/users/FormUser.vue'),
        beforeEnter: (to, from, next) => {
          const { isSuperAdmin } = store.state.auth.user;
          if (!isSuperAdmin) {
            next({ name: 'Campaigns' });
          }
          next();
        }
      },
      {
        path: '/admin/users/edit/:id',
        name: 'EditUser',
        meta: { main: true },
        component: () => import(/* webpackChunkName: "EditUser" */ '../views/users/FormUser.vue'),
        beforeEnter: (to, from, next) => {
          const { isSuperAdmin } = store.state.auth.user;
          if (!isSuperAdmin) {
            next({ name: 'Campaigns' });
          }
          next();
        }
      },
    ],
  },
  {
    path: '/admin/default2',
    name: 'Default2',
    component: () => import(/* webpackChunkName: "Default2" */ '../layouts2/Default2.vue'),
    redirect: '/admin/entries/:id',
    children: [
      {
        path: '/admin/entries/:id',
        name: 'Entries',
        component: () => import(/* webpackChunkName: "Entries" */ '@/views/entries/Entries.vue'),
        meta: { totalEntries: 'Total Entries' },
        beforeEnter: (to, from, next) => {
          store.dispatch('menu/GET_ACTIVE_INDEX', '1');
          next();
        },
        props: async (route) => {
          const { id } = route.params;
          if (id !== store.state.campaigns.id ) {
            await useExploreFromDirectLink(id);
          }
          return { id };
        }
      },
      {
        path: '/admin/moderation-entries/:id',
        name: 'ModerationEntries',
        component: () => import(/* webpackChunkName: "ModerationEntries" */ '@/views/entries/Entries.vue'),
        meta: { isSuperAdmin: true },
        beforeEnter: (to, from, next) => {
          store.dispatch('menu/GET_ACTIVE_INDEX', '1');
          next();
        },
        props: async (route) => {
          const { id } = route.params;
          if (id !== store.state.campaigns.id ) await useExploreFromDirectLink(id);
          return { id };
        }
      },
      {
        path: '/admin/instantwin-entries/:id',
        name: 'InstantwinEntries',
        component: () => import(/* webpackChunkName: "InstantwinEntries" */ '@/views/entries/Entries.vue'),
        props: async (route) => {
          const { id } = route.params;
          if (id !== store.state.campaigns.id ) await useExploreFromDirectLink(id);
          return { id };
        }
      },
      {
        path: '/admin/approve-entries/:id',
        name: 'ApproveEntries',
        component: () => import(/* webpackChunkName: "ApproveEntries" */ '@/views/entries/Entries.vue'),
        meta: { isSuperAdmin: true },
        props: async (route) => {
          const { id } = route.params;
          if (id !== store.state.campaigns.id ) await useExploreFromDirectLink(id);
          return { id };
        }
      },
      {
        path: '/admin/scratchwin-entries/:id',
        name: 'ScratchwinEntries',
        component: () => import(/* webpackChunkName: "ScratchwinEntries" */ '@/views/entries/Entries.vue'),
        meta: { totalEntries: 'Total Entries', prizesGiven: 'Prizes Given', totalPrizes: 'Total Prizes' },
        props: async (route) => {
          const { id } = route.params;
          if (id !== store.state.campaigns.id ) await useExploreFromDirectLink(id);
          return { id };
        }
      },
      {
        path: '/admin/form-builder/:id',
        name: 'ConfigForm',
        component: () => import(/* webpackChunkName: "ConfigForm" */ '../views/config-form/ConfigForm.vue'),
        meta: { isSuperAdmin: true },
        beforeEnter: (to, from, next) => {
          store.dispatch('menu/GET_ACTIVE_INDEX', '4');
          store.state.entries.selectFilter = '';
          store.state.entries.selectEntryIntelligence = 'all';
          store.state.entries.entrySearch = '';
          next();
        },
        props: async (route) => {
          const { id } = route.params;
          if (id !== store.state.campaigns.id ) await useExploreFromDirectLink(id);
          return { id };
        }
      },
      {
        path: '/admin/settings/:id',
        // name: 'Settings',
        // component: Settings,
        component: {
          render(c) {
            return c('router-view');
          }
        },
        children: [
          {
            path: '',
            name: 'Settings',
            component: () => import(/* webpackChunkName: "Settings" */ '../views/settings2/Settings.vue'),
          },
          {
            path: 'bonus_entries',
            name: 'BonusEntries',
            component: () => import(/* webpackChunkName: "BonusEntries" */ '../views/settings/components/bonus-entries')
          },
          {
            path: 'moderation',
            name: 'Moderation',
            component: () => import(/* webpackChunkName: "Moderation" */ '../views/settings/components/rating-moderation')
          },
          {
            path: 'scratch_win',
            name: 'ScratchWin',
            component: () => import(/* webpackChunkName: "ScratchWin" */ '../views/settings/components/scratch-win')
          },
          {
            path: 'instant_win',
            name: 'InstantWin',
            component: () => import(/* webpackChunkName: "InstantWin" */ '../views/settings/components/instant-win')
          },
        ],
        beforeEnter: (to, from, next) => {
          store.state.entries.selectFilter = '';
          store.state.entries.selectEntryIntelligence = 'all';
          store.state.entries.entrySearch = '';
          next();
        },
        props: async (route) => {
          const { id } = route.params;
          if (id !== store.state.campaigns.id ) await useExploreFromDirectLink(id);
          return { id };
        }
      },
      {
        path: '/admin/official-rules/:id',
        name: 'OfficialRulesForm',
        component: () => import(/* webpackChunkName: "OfficialRulesForm" */ '../views/official-rules/OfficialRulesForm.vue'),
        props: async (route) => {
          const { id } = route.params;
          if (id !== store.state.campaigns.id ) await useExploreFromDirectLink(id);
          return { id };
        }
      },
      {
        path: '/admin/winners/:id',
        name: 'Winners',
        component: () => import(/* webpackChunkName: "Winners" */ '../views/winners/Winners.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('menu/GET_ACTIVE_INDEX', '3');
          store.state.entries.selectFilter = '';
          store.state.entries.selectEntryIntelligence = 'all';
          store.state.entries.entrySearch = '';
          next();
        },
        props: async (route) => {
          const { id } = route.params;
          if (id !== store.state.campaigns.id ) await useExploreFromDirectLink(id);
          return { id };
        }
      },
      {
        path: '/admin/milo-reports/:id',
        name: 'MiloReports',
        component: () => import(/* webpackChunkName: "MiloReports" */ '../views/milo-reports/MiloReports.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('menu/GET_ACTIVE_INDEX', '2');
          store.state.entries.selectFilter = '';
          store.state.entries.selectEntryIntelligence = 'all';
          store.state.entries.entrySearch = '';
          next();
        },
        props: async (route) => {
          const { id } = route.params;
          if (id !== store.state.campaigns.id ) await useExploreFromDirectLink(id);
          return { id };
        }
      },
      {
        path: '/admin/entry-detail',
        name: 'EntryDetail',
        component: () => import(/* webpackChunkName: "EntryDetail" */ '../views/detail-entry/DetailEntry.vue'),
        meta: { isSuperAdmin: true },
        props: async (route) => {
          let { id } = route.params;
          if(!id) id = route.query.program;
          if (id !== store.state.campaigns.id ) await useExploreFromDirectLink(id);
          return { id };
        }
      },
      {
        path: '/admin/unauthorized-entries/:id',
        name: 'UnauthorizedEntries',
        component: () => import(/* webpackChunkName: "UnauthorizedEntries" */ '../views/unauthorized/UnauthorizedEntries.vue'),
        props: async (route) => {
          const { id } = route.params;
          if (id !== store.state.campaigns.id ) await useExploreFromDirectLink(id);
          return { id };
        }
      },
      {
        path: '/admin/form-builder-v2/:id',
        name: 'FormBuilder2',
        component: () => import(/* webpackChunkName: "FormBuilder2" */ '../views/form-builder-v2/FormBuilderV2.vue'),
        meta: { isSuperAdmin: true },
        beforeEnter: (to, from, next) => {
          store.dispatch('menu/GET_ACTIVE_INDEX', '7');
          store.state.entries.selectFilter = '';
          store.state.entries.selectEntryIntelligence = 'all';
          store.state.entries.entrySearch = '';
          next();
        },
        props: async (route) => {
          const { id } = route.params;
          if (id !== store.state.campaigns.id ) await useExploreFromDirectLink(id);
          return { id };
        }
      },
    ],
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!store.state.auth.id && !store.state.auth.token && to.name !== 'Login' && to.path !== '/') {
    next({ name: 'Login' });
  } else if (!store.state.auth.id && !store.state.auth.token && to.name === 'Login') {
    next();
  } else if (store.state.auth.token && (to.name === 'Login')) {
    next({ name: 'Default' });
  } else {
    next();
  }
});


export default router
