/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
const state = {
  moderation: ''
};

const mutations = {
  SET_MODERATION(state, data) {
    state.moderation = data;
  },
  SET_REMOVE_MODERATION(state) {
    state.moderation = '';
  },
};

const actions = {
  GET_MODERATION({ commit }, data) {
    commit('SET_MODERATION', data);
  },
};

const getters = {
  moderation: (state) => {
    return state.moderation;
  },
};

export default {
  namespaced: true, state, mutations, actions, getters
};
