import { http } from '@/api/fetch';
import Response from '../../../utils/response';

const state = {
  entryFields: [],
  userFields: [],
  scoreCard: [],
  modarationJudges: [],
  approveLabel: '',
  denyLabel: '',
  scaleType: '',
  mode: '',
  loading: false,
  loadingSection: false,
  isQualified: false,
};

const mutations = {
  SET_ENTRY_FIELDS(state, data) {
    state.entryFields = data;
  },
  SET_USER_FIELDS(state, data) {
    state.userFields = data;
  },
  SET_SCORE_CARD(state, data) {
    state.scoreCard = data;
  },
  SET_MODERATION_JUDGES(state, data) {
    state.modarationJudges = data;
  },
  SET_APPROVE_LABEL(state, data) {
    state.approveLabel = data;
  },
  SET_DENY_LABEL(state, data) {
    state.denyLabel = data;
  },
  SET_SCALE_TYPE(state, data) {
    state.scaleType = data;
  },
  SET_MODE(state, data) {
    state.mode = data;
  },
  SET_IS_QUALIFIED(state, data) {
    state.isQualified = data;
  },
};

const actions = {
  GET_MODERATIONS_FIELDS({ commit, state }, { programId, env }) {
    state.loadingSection = true;
    http('get', `api/moderation/config/moderation-fields/${env}/${programId}`).then((response) => {
      console.table('response: ', response);
      const {
        approve_label, deny_label, scale_type,
        entryFields, userFields, score_card, isQualified
      } = response.data;

      if (response.statusCode === Response.HTTP_OK) {
        commit('SET_APPROVE_LABEL', approve_label);
        commit('SET_DENY_LABEL', deny_label);
        commit('SET_ENTRY_FIELDS', entryFields);
        commit('SET_USER_FIELDS', userFields);
        commit('SET_SCORE_CARD', score_card);
        commit('SET_SCALE_TYPE', scale_type);
        commit('SET_IS_QUALIFIED', isQualified);
        state.loadingSection = false;
      }
    }).catch(() => {
      state.loadingSection = false;
    });
  },

  GET_MODERATIONS_JUDGES({ commit }, programId) {
    http('get', `api/moderation-judges/${programId}`).then((response) => {
      const { statusCode, data } = response;

      if (statusCode === Response.HTTP_OK) {
        commit('SET_MODERATION_JUDGES', data);
      }
    });
  },

};

const getters = {
  entryFields: (state) => {
    return state.entryFields;
  },
  userFields: (state) => {
    return state.userFields;
  },
  scoreCard: (state) => {
    return state.scoreCard;
  },
  modarationJudges: (state) => {
    return state.modarationJudges;
  },
  approveLabel: (state) => {
    return state.approveLabel;
  },
  denyLabel: (state) => {
    return state.denyLabel;
  },
  scaleType: (state) => {
    return state.scaleType;
  },
  mode: (state) => {
    return state.mode;
  },
  isQualified: (state) => {
    return state.isQualified;
  },
};

export default {
  namespaced: true, state, mutations, actions, getters
};
