import { http } from '@/api/fetch';

const state = {
  id: '',
  judges: [],
  scoreCardRating: [],
  scoreCard: [],
  entryInfo: [],
  userInfo: [],
  comments: [],
  entry: {},
  totalRatingAdmins: 0,
  totalRatingNormal: 0,
  createdAt: '',
  user: {},
  userType: '',
  privileges: [],
  moderationEntryFileTypes: {},
  firstNameField: false,
  lastNameField: false,
};

const mutations = {
  SET_JUDGES(state, data) {
    state.judges = data;
  },
  SET_SCORE_CARD_RATING(state, data) {
    state.scoreCardRating = data;
  },
  SET_SCORE_CARD(state, data) {
    state.scoreCard = data;
  },
  SET_ENTRY_INFO(state, data) {
    state.entryInfo = data;
  },
  SET_USER_INFO(state, data) {
    state.userInfo = data;
  },
  SET_COMMENTS(state, data) {
    state.comments = data;
  },
  SET_ENTRY(state, data) {
    state.entry = data;
  },
  SET_TOTAL_RATING_ADMIN(state, data) {
    state.totalRatingAdmins = data;
  },
  SET_TOTAL_RATING_NORMAL(state, data) {
    state.totalRatingNormal = data;
  },
  SET_CREATED_AT(state, data) {
    state.createdAt = data;
  },
};

const actions = {
  async GET_MODERATIONS_ENTRIES_DETAIL({ commit }, {
    env, program, entry, userLoggedIn
  }) {

    let arrayJudges = [];
    let arrayScoreCard = [];
    const [getModeration, getScoreCard] = await Promise.all([
      await http('get', `/api/moderation/data/${env}/${program}/${Number(entry)}`),
      await http('get', `api/get-score-cards/${env}/${program}/${Number(entry)}`)
    ]);

    const { data: dataModeration } = getModeration;
    const { data: dataScoreCard } = getScoreCard;

    const {
      // eslint-disable-next-line no-unused-vars
      entry: entryModeration, fields,
      moderation_config: {
        // eslint-disable-next-line no-unused-vars
        entry_fields, user_fields, judges, adminJudges,
        // eslint-disable-next-line no-unused-vars
        normalJudges, score_card, users_judges, scale_type
      },
      prerender: { entry_info, user_info }
    } = dataModeration;

    const { totalRatingAdmins, totalRatingNormal, ratingJudges } = dataScoreCard;

    arrayJudges = judges.map((judge) => {
      let temp = {
        _id: judge._id, label: judge.label, name: judge.name, rating: 0
      };
      ratingJudges.filter((rating) => {
        if (judge._id === rating.user_id) {
          temp.rating = rating.rating;
          //
        }
      });

      return temp;
    });

    ratingJudges.forEach((score) => {
      arrayScoreCard[score.user_id] = score.score_card;
    });

    const index = arrayJudges.findIndex((x) => x._id === userLoggedIn);
    let temp2 = arrayJudges[0];
    arrayJudges[0] = arrayJudges[index];
    arrayJudges[index] = temp2;

    commit('SET_JUDGES', arrayJudges);
    commit('SET_SCORE_CARD', score_card);
    commit('SET_ENTRY', entryModeration);
    commit('SET_ENTRY_INFO', entry_info);
    commit('SET_USER_INFO', user_info);
    commit('SET_TOTAL_RATING_ADMIN', totalRatingAdmins);
    commit('SET_TOTAL_RATING_NORMAL', totalRatingNormal);
    commit('SET_SCORE_CARD_RATING', arrayScoreCard);
  },
};

const getters = {
  id: (state) => {
    return state.id;
  },
  judges: (state) => {
    return state.judges;
  },
  scoreCard: (state) => {
    return state.scoreCard;
  },
  scoreCardRating: (state) => {
    return state.scoreCardRating;
  },
  entryInfo: (state) => {
    return state.entryInfo;
  },
  userInfo: (state) => {
    return state.userInfo;
  },
  entry: (state) => {
    return state.entry;
  },
  totalRatingAdmins: (state) => {
    return state.totalRatingAdmins;
  },
  totalRatingNormal: (state) => {
    return state.totalRatingNormal;
  },
};

export default {
  namespaced: true, state, mutations, actions, getters
};
