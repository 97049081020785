import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';

import menu from './modules/horizontal-menu';
import auth from './modules/auth';
import brands from './modules/brands';
import users from './modules/users';
import campaigns from './modules/campaigns';
import entries from './modules/entries';
import upload from './modules/upload';
import fields from './modules/fields';
import moderations from './modules/moderations';
import header from './modules/header';
import settings from './modules/settings';
import utilities from './modules/utilities';
import moderation from './modules/plugins/moderation';
import approveentries from './modules/plugins/approveentries';
import moderationEntriesDetail from './modules/entries/moderation-entries-detail';

const ls = new SecureLS({
  isCompression: true,
  encodingType: 'aes',
  encryptionSecret: 'gyGM8Q[u-zjMfj%a'
});

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showDialog: false,
    showCloneDialog: false
  },
  getters: {
  },
  mutations: {
    IS_DIALOG(state) {
      state.showDialog = !state.showDialog;
    },
    IS_CLONE_DIALOG(state) {
      state.showCloneDialog = !state.showCloneDialog;
    },
  },
  actions: {
  },
  modules: {
    auth,
    brands,
    users,
    menu,
    campaigns,
    entries,
    moderationEntriesDetail,
    upload,
    fields,
    moderations,
    header,
    settings,
    utilities,
    moderation,
    approveentries
  },
  plugins: [createPersistedState({
    key: '_data_',
    paths: [
      'auth.id',
      'auth.email',
      'auth.password',
      'auth.token',
      'auth.user',
      'auth.expire',
      'menu.activeIndex',
      'brands.nameBrand',
      'brands.image',
      'campaigns.id',
      'campaigns.miloreportid',
      'campaigns.type',
      'campaigns.start',
      'campaigns.ends',
      'campaigns.campaignName',
      'campaigns.campaignStatus',
      'campaigns.dbserver',
      'campaigns.plug_ins',
      'entries.selectFilter',
      'entries.selectEntryIntelligence',
      'entries.entrySearch',
      'header.moderation',
      'moderations.page',
      'moderations.isJudge',
      'moderations.rated',
      'moderations.displaying',
      'moderations.env',
      'moderations.sort',
      'moderations.myratingfilter',
      'moderation.isQualified',
      'settings.ratingModeration',
      'settings.pluginName',
      'settings.pluginKey',
      'utilities.pluginStatus',
      'utilities.pluginConfiguration',
      'utilities.page',
      'utilities.displaying',
      'utilities.env',
      'utilities.path',
      'utilities.urlFields',
      'utilities.urlEntries',
      'utilities.urlNavigation',
      'utilities.currentComponent',
      'utilities.propietier',
      'utilities.sortTableColumn',
      'utilities.pageBackup',
      'utilities.winnerEmailPreview',
      'utilities.viewEntry',
      'utilities.customMediaFilter',
    ],
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key)
    }
  })]
})
