import { fetch } from '@/api/fetch';

const state = {
  fields_programs: [],
  columns_programs: [],
};

const mutations = {
  SET_FIELDS_PROGRAMS(state, data) {
    state.fields_programs = data;
  },
  SET_COLUMS_PROGRAMS(state, data) {
    state.columns_programs = data;
  },
};

const actions = {
  GET_FIELDS_PROGRAMS({ commit }, data) {
    fetch('get', `/api/get-fields-program/${data}`).then((response) => {
      const { statusCode, data } = response;

      if (statusCode === 200) {
        const columns = data.map((f) => {
          // eslint-disable-next-line no-unused-vars
          const { name, field_type: type, field_width: width } = f;

          const prop = f.name;
          const label = f.name.toUpperCase();
          // const label = capitalize(f.name.replace('_', ' '));
          // const type = f.field_type;

          if (f.field_options.length > 0) {
            console.log('centrar');
          }

          const align = 'left';

          return {
            prop, label, align, width
          };
        });

        columns.push({
          prop: 'createdAt',
          label: 'createdAt'.toUpperCase(),
          align: 'left',
          type: 'datetime',
          width: '180'
        });

        columns.unshift({
          prop: 'id',
          label: 'Id'.toUpperCase(),
          align: 'center',
          width: '75'
        });

        commit('SET_COLUMS_PROGRAMS', columns);
        commit('SET_FIELDS_PROGRAMS', data);
      }
    });
  },
};

const getters = {
  fieldsPrograms: (state) => {
    return state.fields_programs;
  },
  columnsPrograms: (state) => {
    return state.columns_programs;
  },
};

export default {
  namespaced: true, state, mutations, actions, getters
};
