/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
const state = {
  url: '',
  imgPreview: '',
  emailHeaderUrl: '',
  emailFooterUrl: '',
};

const mutations = {
  SEL_URL(state, data) {
    state.url = data;
  },
  REMOVE_URL(state) {
    state.url = '';
  },
  SEL_EMAIL_HEADER_URL(state, data) {
    state.emailHeaderUrl = data;
  },
  REMOVE_EMAIL_HEADER_URL(state) {
    state.emailHeaderUrl = '';
  },
  SEL_EMAIL_FOOTER_URL(state, data) {
    state.emailFooterUrl = data;
  },
  REMOVE_EMAIL_FOOTER_URL(state) {
    state.emailFooterUrl = '';
  },
};

const actions = {
  GEL_URL({ commit }, data) {
    commit('SEL_URL', data);
  },
  GEL_EMAIL_HEADER_URL({ commit }, data) {
    commit('SEL_EMAIL_HEADER_URL', data);
  },
  GEL_EMAIL_FOOTER_URL({ commit }, data) {
    commit('SEL_EMAIL_FOOTER_URL', data);
  },
};

const getters = {
  url: (state) => {
    return state.url;
  },
  emailHeaderUrl: (state) => {
    return state.emailHeaderUrl;
  },
  emailFooterUrl: (state) => {
    return state.emailFooterUrl;
  },
};

export default {
  namespaced: true, state, mutations, actions, getters
};
