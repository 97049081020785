import { fetch } from '@/api/fetch';
import Response from '../../utils/response';

const state = {
  id: '',
  campaigns: [],
  miloreportid: '',
  campaignName: '',
  campaignStatus: '',
  loadingTable: false,
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  totalCampaigns: 0,
  totalSweepstake: 0,
  totalContest: 0,
  type: '',
  url_test: '',
  url_prod: '',
  start: '',
  ends: '',
  clone: { id: '', title: '' },
  dbserver: '',
  fulldates: {
    start_date: 'zzzz',
    start_hour: '',
    end_date: '',
    end_hour: ''
  },
  plug_ins: []
};

const mutations = {
  SET_ID(state, data) {
    state.id = data;
  },
  SET_REMOVE_ID(state) {
    state.id = '';
  },
  SET_CAMPAINNS(state, data) {
    state.campaigns = data;
  },
  SET_REMOVE_CAMPAINNS(state) {
    state.campaigns = [];
  },
  SET_TOTAL_ITEMS(state, data) {
    state.totalItems = data;
  },
  SET_TOTAL_PAGES(state, data) {
    state.totalPages = data;
  },
  SET_REMOVE_TOTAL_PAGES(state) {
    state.totalItems = 0;
  },
  SET_CURRENT_PAGE(state, data) {
    state.currentPage = data;
  },
  SET_REMOVE_CURRENT_PAGE(state) {
    state.currentPage = 0;
  },
  SET_TOTAL_CAMPAINNS(state, data) {
    state.totalCampaigns = data;
  },
  SET_TOTAL_SWEEPSTAKE(state, data) {
    state.totalSweepstake = data;
  },
  SET_TOTAL_CONTEST(state, data) {
    state.totalContest = data;
  },
  SET_TYPE_CAMPAINNS(state, data) {
    state.type = data;
  },
  SET_START(state, data) {
    state.start = data;
  },
  SET_REMOVE_START(state) {
    state.start = '';
  },
  SET_ENDS(state, data) {
    state.ends = data;
  },
  SET_FULLDATES(state, data) {
    state.fulldates = data;
  },
  SET_REMOVE_ENDS(state) {
    state.ends = '';
  },
  SET_CAMPAINN_NAME(state, data) {
    state.campaignName = data;
  },
  SET_CAMPAINN_STATUS(state, data) {
    state.campaignStatus = data;
  },
  SET_DBSERVER(state, data) {
    state.dbserver = data;
  },
  SET_PLUG_INS(state, data) {
    state.plug_ins = data;
  },
};

const actions = {
  GET_CAMPAINNS({ commit, state }, _data) {
    const { order, property } = _data.sort;
    const queryParams = `?page=${_data.page}&per_page=${_data.perPage}&brand=${_data.brand}&type=${_data.type}&status=${_data.status}&search=${_data.search}`;
    state.loadingTable = true;
    fetch('get', `/api/get-programs${queryParams}&sort=${property}|${order}`).then((response) => {
      const { statusCode, data } = response;

      if (statusCode === Response.HTTP_OK) {

        const dtt = [];

        data.items.forEach( ( dt ) => {
          if ( dt && dt.title) dtt.push( dt );
        } );

        commit('SET_CAMPAINNS', dtt);
        commit('SET_TOTAL_ITEMS', data.total_items);
        commit('SET_TOTAL_PAGES', data.total_pages);
        commit('SET_CURRENT_PAGE', data.page);
        state.loadingTable = false;
      } else {
        commit('SET_REMOVE_CAMPAINNS');
        commit('SET_REMOVE_TOTAL_PAGES');
        commit('SET_REMOVE_CURRENT_PAGE');
        state.loadingTable = false;
      }
      if ( _data.callback !== undefined && _data.callback !== null && typeof _data.callback === 'function' ) {
        _data.callback();
      }
    }).catch(() => {
      state.loadingTable = false;
    });
  },

  GET_TOTAL_CAMPAINNS({ commit }) {
    fetch('get', '/api/total-programs').then((response) => {
      const { statusCode, data } = response;

      if (statusCode === Response.HTTP_OK) {
        commit('SET_TOTAL_CAMPAINNS', data.programs);
        commit('SET_TOTAL_SWEEPSTAKE', data.sweepstake);
        commit('SET_TOTAL_CONTEST', data.contest);
      }
    });
  },

  GET_CAMPAINN_NAME({ commit }, data) {
    commit('SET_CAMPAINN_NAME', data);
  },
  GET_CAMPAINN_STATUS({ commit }, data) {
    commit('SET_CAMPAINN_STATUS', data);
  },
  GET_TYPE_CAMPAINNS({ commit }, data) {
    commit('SET_TYPE_CAMPAINNS', data);
  },
  GET_ID({ commit }, data) {
    commit('SET_ID', data);
  },
  GET_START({ commit }, data) {
    commit('SET_START', data);
  },
  GET_ENDS({ commit }, data) {
    commit('SET_ENDS', data);
  },
  GET_DBSERVER({ commit }, data) {
    commit('SET_DBSERVER', data);
  },
  GET_FULLDATES({ commit }, data) {
    commit('SET_FULLDATES', data);
    console.log('---my data--');
    console.log(data);
    // vm.$recompute('key')
  }
};

const getters = {
  campaignId: (state) => {
    return state.id;
  },
  fullCampaignDates: () => {
    return state.fulldates;
  },
  campaignName: (state) => {
    return state.campaignName;
  },
  campaignStatus: (state) => {
    return state.campaignStatus;
  },
  campaigns: (state) => {
    return state.campaigns;
  },
  totalItems: (state) => {
    return state.totalItems;
  },
  totalPages: (state) => {
    return state.totalPages;
  },
  currentPage: (state) => {
    return state.currentPage;
  },
  totalCampaigns: (state) => {
    return state.totalCampaigns;
  },
  totalSweepstake: (state) => {
    return state.totalSweepstake;
  },
  totalContest: (state) => {
    return state.totalContest;
  },
  typeCampaign: (state) => {
    return state.type;
  },
  start: (state) => {
    return state.start;
  },
  ends: (state) => {
    return state.ends;
  },
  dbserver: (state) => {
    return state.dbserver;
  },
};

export default {
  namespaced: true, state, mutations, actions, getters
};
