import { fetch } from '@/api/fetch';
import Response from '../../utils/response';

const state = {
  brands: [],
  servers: [],
  default_server: '',
  nameBrand: '',
  image: '',
  totalBrands: 0,
  showLoader: false,
};

const mutations = {
  SET_BRANDS(state, data) {
    state.brands = data;
  },
  SET_DEFAULT_SERVER(state, data) {
    state.default_server = data;
  },
  SET_REMOVE_BRANDS(state) {
    state.brands = [];
  },
  SET_SERVERS(state, data) {
    state.servers = data;
  },
  SET_REMOVE_SERVERS(state) {
    state.servers = [];
  },
  SET_TOTAL_BRANDS(state, data) {
    state.totalBrands = data;
  },
  SET_NAME_BRAND(state, data) {
    state.nameBrand = data;
  },
  SET_IMAGE_BRAND(state, data) {
    state.image = data;
  },
};

const actions = {
  GET_BRANDS({ commit }) {
    fetch('get', '/api/get-brands').then((response) => {
      const { statusCode, data } = response;

      if (statusCode === Response.HTTP_OK) {
        commit('SET_BRANDS', data.items);
        commit('SET_DEFAULT_SERVER', data.default_server);
        commit('SET_SERVERS', data.servers);
      } else {
        commit('SET_REMOVE_BRANDS');
        commit('SET_REMOVE_SERVERS');
      }
    });
  },

  GET_TOTAL_BRANDS({ commit }) {
    fetch('get', '/api/total-brands').then((response) => {
      const { statusCode, data } = response;

      if (statusCode === Response.HTTP_OK) {
        commit('SET_TOTAL_BRANDS', data.count);
      }
    });
  },

  GET_NAME_BRAND({ commit }, data) {
    commit('SET_NAME_BRAND', data);
  },

  GET_IMAGE_BRAND({ commit }, data) {
    commit('SET_IMAGE_BRAND', data);
  },
};

const getters = {
  brands: (state) => {
    return state.brands;
  },
  servers: (state) => {
    return state.servers;
  },
  default_server: (state) => {
    return state.default_server;
  },
  totalBrands: (state) => {
    return state.totalBrands;
  },
  nameBrand: (state) => {
    return state.nameBrand;
  },
  imageBrand: (state) => {
    return state.image;
  },
};

export default {
  namespaced: true, state, mutations, actions, getters
};
