/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
const state = {
  id: '',
  email: '',
  password: '',
  token: '',
  expire: null,
  user: {},
  userType: '',
  privileges: [],
  globalPrivileges: []
};

const mutations = {
  SET_ID(state, id) {
    state.id = id;
  },
  SET_REMOVE_ID(state) {
    state.id = '';
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_REMOVE_TOKEN(state) {
    state.token = '';
  },
  SET_EXPIRE(state, data) {
    state.expire = data;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_REMOVE_USER(state) {
    state.user = {};
  },
  SET_EMAIL(state, email) {
    state.email = email;
  },
  SET_REMOVE_EMAIL(state) {
    state.email = '';
  },
  SET_PASSWORD(state, password) {
    state.password = password;
  },
  SET_REMOVE_PASSWORD(state) {
    state.password = '';
  },
};

const actions = {
  GET_ID({ commit }, data) {
    commit('SET_ID', data);
  },
  GET_EMAIL({ commit }, data) {
    commit('SET_EMAIL', data);
  },
  GET_PASSWORD({ commit }, data) {
    commit('SET_PASSWORD', data);
  },
  GET_TOKEN({ commit }, data) {
    commit('SET_TOKEN', data);
    commit('SET_USER', data);
  },
  GET_USER({ commit }, data) {
    commit('SET_USER', data);
  },
  GET_EXPIRE({ commit }, data) {
    commit('SET_EXPIRE', data);
  },
};

const getters = {
  id: (state) => {
    return state.id;
  },
  email: (state) => {
    return state.email;
  },
  password: (state) => {
    return state.password;
  },
  token: (state) => {
    return state.token;
  },
  expire: (state) => {
    return state.expire;
  },
  user: (state) => {
    return state.user;
  },
  permissions: (state) => (programId) => {
    let { isSuperAdmin, permissions } = state.user;
    // let arr = permissions.filter((item) => item.program === programId);
    permissions.forEach((item) => {
      if (!isSuperAdmin) {
        if (item.program === programId) {
          state.userType = item.userType;
          state.privileges = item.privileges;
        }
      } else {
        state.userType = item.userType;
        state.privileges = item.privileges;
      }
    });
    return {
      userType: state.userType,
      privileges: state.privileges
    };
  },
  privileges: (state) => {
    return state.privileges;
  },
};

export default {
  namespaced: true, state, mutations, actions, getters
};
