import { http } from '@/api/fetch';
import Response from '../../../utils/response';

// data
const state = {
  approvedFields: [],
  approvedColumns: [],
  entryFields: [],
  userFields: [],
  approveLabel: '',
  denyLabel: '',
  env: 'test',
  loadingTable: false,
  count: 0,
  pending: 0,
  rated: 0,
  sort: '',
  totalPages: 0,
  page: 1,
  displaying: 10
};

// mothods
const mutations = {
  SET_ENTRY_FIELDS(state, data) {
    state.entryFields = data;
  },
  SET_USER_FIELDS(state, data) {
    state.userFields = data;
  },

  SET_APPROVE_LABEL(state, data) {
    state.approveLabel = data;
  },
  SET_DENY_LABEL(state, data) {
    state.denyLabel = data;
  },

  SET_ENV(state, data) {
    state.env = data;
  },

  SET_COUNT(state, data) {
    state.count = data;
  },

  SET_PENDING(state, data) {
    state.pending = data;
  },

  SET_RATED(state, data) {
    state.rated = data;
  },

  SET_TOTAL_PAGES(state, data) {
    state.totalPages = data;
  },

  SET_APPROVED_COLUMNS(state, data) {
    state.approvedColumns = data;
  },

  // eslint-disable-next-line no-unused-vars
  SET_REMOVE_APPROVED_COLUMNS(state, data) {
    state.approvedColumns = [];
  },

  SET_APPROVED_FIELDS(state, data) {
    state.approvedFields = data;
  },
  // eslint-disable-next-line no-unused-vars
  SET_REMOVE_APPROVED_FIELDS(state, data) {
    state.approvedFields = [];
  },
  SET_SORT(state, data) {
    state.sort = data;
  },
  SET_PAGE(state, data) {
    state.page = data;
  },
  SET_DISPLAYING(state, data) {
    state.displaying = data;
  },

};

// computed
const actions = {
  async GET_APPROVE_FIELDS({ commit, state }, _data) {

    const {
      page, size, search, env, programId, sort
    } = _data;

    state.loadingTable = true;
    const [fieldsApprove, getApproveEntries, pluginConfig] = await Promise.all([
      http('get', `api/get-fields-approve-entries/${programId}`),
      http('get', `api/approve-entries-all/${env}/${programId}?excel=false&page=${page}&size=${size}&sort=${sort}&search=${search}`),
      http('get', `api/plugins/approve-entries/config/${programId}`),
    ]);

    const { statusCode, data } = fieldsApprove;

    const {
      count, total_pages, content, pending, scores
    } = getApproveEntries.data;

    if ( statusCode !== Response.HTTP_OK ) return;

    const arrayColumns = data.findIndex((el) => el.label === 'rating');

    if (arrayColumns >= 0) data.splice(arrayColumns, 1);

    const columns = data.map((f) => {
      // eslint-disable-next-line no-unused-vars
      const { name, field_type: type, field_width: width } = f;

      const prop = f.name;
      const label = f.name.toUpperCase();

      const align = 'left';

      return {
        prop, label, align, width
      };

    });

    columns.push({
      prop: 'createdAt',
      label: 'createdAt'.toUpperCase(),
      align: 'left',
      type: 'datetime',
      width: '180'
    });

    columns.unshift({
      prop: 'id',
      label: 'Id'.toUpperCase(),
      align: 'center',
      width: '75'
    }, {
      prop: 'rating',
      label: 'rating'.toUpperCase(),
      align: 'center',
      width: '130',
      type: 'approve',
      approved_if: pluginConfig.data.approve_label
    });

    let entry_fields = [];
    pluginConfig.data.entry_fields.forEach((element) => {
      if ( element.field_required ) {
        entry_fields.push( element );
      }
    });

    commit('SET_COUNT', count);
    commit('SET_PENDING', pending);
    commit('SET_RATED', scores);
    commit('SET_TOTAL_PAGES', total_pages);
    commit('SET_APPROVED_COLUMNS', columns);
    commit('SET_APPROVED_FIELDS', content);
    commit('SET_APPROVE_LABEL', pluginConfig.data.approve_label);
    commit('SET_DENY_LABEL', pluginConfig.data.deny_label);
    commit('SET_USER_FIELDS', pluginConfig.data.user_fields);
    commit('SET_ENTRY_FIELDS', entry_fields);
    state.loadingTable = false;

  },
  GET_PAGE({ commit }, data) {
    commit('SET_PAGE', data);
  },
  GET_DISPLAYING({ commit }, data) {
    commit('SET_DISPLAYING', data);
  },
  GET_ENV({ commit }, data) {
    commit('SET_ENV', data);
  },
  GET_SORT({ commit }, data) {
    commit('SET_SORT', data);
  },

};

const getters = {
  count: (state) => {
    return state.count;
  },
  pending: (state) => {
    return state.pending;
  },
  rated: (state) => {
    return state.rated;
  },
  page: (state) => {
    return state.page;
  },
  displaying: (state) => {
    return state.displaying;
  },
  env: (state) => {
    return state.env;
  },
  sort: (state) => {
    return state.sort;
  },
  approvedFields: (state) => {
    return state.approvedFields;
  },
  approvedColumns: ( state ) => {
    return state.approvedColumns;
  },
  userFields: (state) => {
    return state.userFields;
  },
  entryFields: (state) => {
    return state.entryFields;
  },
  approveLabel: (state) => {
    return state.approveLabel;
  },
  denyLabel: (state) => {
    return state.denyLabel;
  },
  totalPages: (state) => {
    return state.totalPages;
  },
};

export default {
  namespaced: true, state, mutations, actions, getters
};
