import axios from 'axios';
import store from '../store';


const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
});

service.interceptors.request.use((config) => {
  config.headers.Accept = '*/*';
  // config.headers['User-Agent'] = 'Thunder Client (https://www.thunderclient.io)';
  config.headers['x-token-auth'] = `${store.state.auth.token}`;
  return config;
}, (e) => {
  return Promise.reject(e);
});

service.interceptors.response.use((response) => {
  return response;
}, (error) => {
  return Promise.reject(error);
});

export default service;
