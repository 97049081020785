const state = {
  userFields: [],
  entryFields: [],
  ratingModeration: true,
  pluginKey: '',
  pluginName: '',
};

const mutations = {
  SET_USER_FIELDS(state, data) {
    state.userFields = data;
  },
  SET_ENTRY_FIELDS(state, data) {
    state.entryFields = data;
  },
  SET_RATING_MODERATION(state, data) {
    state.ratingModeration = data;
  },
  SET_PLUGIN_NAME(state, data) {
    state.pluginName = data;
  },
  SET_PLUGIN_KEY(state, data) {
    state.pluginKey = data;
  },
};

const actions = {
  // GET_MODERATIONS_FIELDS({ commit }, data) {
  //   fetch('get', `api/moderation/config/moderation-fields/${data}`).then((response) => {
  //     const { entryFields, userFields } = response.data;

  //     if (response.statusCode === Response.HTTP_OK) {
  //       commit('SET_USER_FIELDS', userFields);
  //       commit('SET_ENTRY_FIELDS', entryFields);
  //     }
  //   });
  // },

  GET_PLUGIN_NAME({ commit }, data) {
    commit('SET_PLUGIN_NAME', data);
  },

  GET_PLUGIN_KEY({ commit }, data) {
    commit('SET_PLUGIN_KEY', data);
  },

  GET_RATING_MODERATION({ commit }, data) {
    commit('SET_RATING_MODERATION', data);
  },
};

const getters = {
  userFields: (state) => {
    return state.userFields;
  },
  entryFields: (state) => {
    return state.entryFields;
  },
  ratingModeration: (state) => {
    return state.ratingModeration;
  },
  pluginName: (state) => {
    return state.pluginName;
  },
  pluginKey: (state) => {
    return state.pluginKey;
  },
};

export default {
  namespaced: true, state, mutations, actions, getters
};
