import { fetch } from '@/api/fetch';
import Response from '../../utils/response';

const state = {
  users: [],
  sysPermissions: [],
  brandsPrograms: [],
  totalItems: 0,
  currentPage: 0,
  totalPages: 0,
  loadingTable: false,
};

const mutations = {
  SET_USERS(state, data) {
    state.users = data;
  },
  SET_REMOVE_USERS(state) {
    state.users = [];
  },
  SET_SYSPERMISSIONS(state, data) {
    state.sysPermissions = data;
  },
  SET_USER_campaignS(state, data) {
    state.usercampaigns = data;
  },
  SET_TOTAL_PAGES(state, data) {
    state.totalItems = data;
  },
  SET_REMOVE_TOTAL_PAGES(state) {
    state.totalItems = 0;
  },
  SET_CURRENT_PAGE(state, data) {
    state.currentPage = data;
  },
  SET_REMOVE_CURRENT_PAGE(state) {
    state.currentPage = 0;
  },
  SET_TOTAL_PAGE(state, data) {
    state.totalPages = data;
  },
};

const actions = {
  GET_USERS({ commit, state }, _data) {
    let url = '';
    if (_data !== undefined) {
      url = `/api/get-users?page=${_data.page}&per_page=${_data.perPage}&sort=${_data.sort}&search=${_data.search}`;
    } else {
      url = '/api/get-users';
    }
    state.loadingTable = true;
    fetch('get', url).then((response) => {
      const { statusCode, data } = response;

      if (statusCode === Response.HTTP_OK) {
        commit('SET_USERS', data.items);
        commit('SET_TOTAL_PAGES', data.total_items);
        commit('SET_TOTAL_PAGE', data.total_pages);
        commit('SET_CURRENT_PAGE', data.page);
        state.loadingTable = false;
      } else {
        commit('SET_REMOVE_USERS');
        state.loadingTable = false;
      }

      if ( _data.callback !== undefined && _data.callback !== null && typeof _data.callback === 'function' ) {
        _data.callback();
      }
    }).catch(() => {
      state.loadingTable = false;
    });
  },
};

const getters = {
  users: (state) => {
    return state.users;
  },
  totalItems: (state) => {
    return state.totalItems;
  },
  currentPage: (state) => {
    return state.currentPage;
  },
  totalPages: (state) => {
    return state.totalPages;
  },
};

export default {
  namespaced: true, state, mutations, actions, getters
};
