import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import locale from 'element-ui/lib/locale/lang/en';
import 'simple-code-editor/themes/themes.css';
import 'simple-code-editor/themes/themes-base16.css';

// import './assets/app.scss'
import './assets/cms.scss'

locale.el.pagination.goto = 'Page';
Vue.use(ElementUI, { locale, size: 'small', zIndex: 3000 })

Vue.directive('lowercase', {
  bind(el) {
    let isProcessing = false;
    el.addEventListener('input', (event) => {
      if (isProcessing) return; // Evitar loop
      isProcessing = true;

      const lowercasedValue = event.target.value.toLowerCase();
      if (event.target.value !== lowercasedValue) {
        event.target.value = lowercasedValue;
        const e = new Event('input', { bubbles: true });
        event.target.dispatchEvent(e);
      }

      isProcessing = false;
    });
  }
});


Vue.directive('clickable', {
  bind(el, binding, vnode) {
    el.addEventListener('click', (event) => {
      const column = vnode.context.columns[binding.value.index];
      if (!column.fixed) {
        binding.value.method(binding.value.row);
      } else {
        event.stopPropagation();
      }
    });
  }
});


// Vue.directive('clickable', {
//   bind(el, binding, vnode) {
//     el.addEventListener('click', (event) => {
//       const column = vnode.context.columns[binding.value.index];
//       if (!column.fixed) {
//         binding.value.method(binding.value.data);
//       } else {
//         event.stopPropagation();
//       }
//     });
//   }
// });

// Prevenir zoom globalmente
document.addEventListener('keydown', function(event) {
  if ((event.ctrlKey || event.metaKey) && (event.key === '+' || event.key === '-' || event.key === '=')) {
      event.preventDefault();
  }
});

document.addEventListener('wheel', function(event) {
  if (event.ctrlKey) {
      event.preventDefault();
  }
}, { passive: false });

document.addEventListener('gesturestart', function(event) {
  event.preventDefault();
});

document.addEventListener('gesturechange', function(event) {
  event.preventDefault();
});

document.addEventListener('gestureend', function(event) {
  event.preventDefault();
});

Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

if (window.Cypress) {
  // only available during E2E tests
  window.app = app
  window.Vuenv = process.env.VUE_APP_BASE_URL
}
