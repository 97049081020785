/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
const state = {
  pluginStatus: '',
  pluginConfiguration: null,
  page: 1,
  masonrypage: 1,
  env: 'prod',
  displaying: 10,
  path: '',
  urlFields: '',
  urlEntries: '',
  urlNavigation: '',
  currentComponent: '',
  propietier: {},
  dataform: {},
  loading: false,
  loadingTable: false,
  sortTableColumn: '',
  pageBackup: '',
  viewEntry: 'other',
  winnerEmailPreview: {},
  // esta la uso para setear el env de la vista fraud detection,
  // dependiendo del estado d ela campaña
  // lo pongo en test o en prod
  auxiliaryCampaignStatus: '',
  dataPrizes: 0,
  customFieldFilter: [],
  customMediaFilter: '',
};

const mutations = {
  SET_LOADING(state, data) {
    state.loading = data;
  },
  SET_PLUGIN_STATUS(state, data) {
    state.pluginStatus = data;
  },
  SET_PLUGIN_CONFIGURATION(state, data) {
    state.pluginConfiguration = data;
  },
  SET_PAGE(state, data) {
    state.page = data;
  },
  SET_MASONRY_PAGE(state, data) {
    state.masonrypage = data;
  },
  SET_DISPLAYING(state, data) {
    state.displaying = data;
  },
  SET_ENV(state, data) {
    state.env = data;
  },
  SET_PATH(state, data) {
    state.path = data;
  },
  SET_URL_FIELDS(state, data) {
    state.urlFields = data;
  },
  SET_URL_ENTRIES(state, data) {
    state.urlEntries = data;
  },
  SET_URL_NAVIGATION(state, data) {
    state.urlNavigation = data;
  },
  SET_DATAFORM(state, data) {
    state.dataform = data;
  },
  SET_CURRENT_COMPONENT(state, data) {
    state.currentComponent = data;
  },
  SET_PROPIETIER(state, data) {
    state.propietier = data;
  },
  SET_WINNER_EMAIL_PREVIEW(state, data) {
    state.winnerEmailPreview = data;
  },
  SET_VIEW_ENTRY(state, data) {
    state.viewEntry = data;
  },
  SET_DATA_PRIZES(state, data) {
    state.dataPrizes = data;
  },
  SET_CUSTOM_FIELD_FILTER(state, data) {
    state.customFieldFilter = data;
  },
  SET_CUSTOM_MEDIA_FILTER(state, data) {
    state.customMediaFilter = data;
  },
};

const actions = {
  GET_LOADING({ commit }, data) {
    commit('SET_LOADING', data);
  },
  GET_PLUGIN_STATUS({ commit }, data) {
    commit('SET_PLUGIN_STATUS', data);
  },
  GET_PLUGIN_CONFIGURATION({ commit }, data) {
    commit('SET_PLUGIN_CONFIGURATION', data);
  },
  GET_PAGE({ commit }, data) {
    commit('SET_PAGE', data);
  },
  GET_MASONRY_PAGE({ commit }, data) {
    commit('SET_MASONRY_PAGE', data);
  },
  GET_DISPLAYING({ commit }, data) {
    commit('SET_DISPLAYING', data);
  },
  GET_ENV({ commit }, data) {
    commit('SET_ENV', data);
  },
  GET_PATH({ commit }, data) {
    commit('SET_PATH', data);
  },
  GET_URL_FIELDS({ commit }, data) {
    commit('SET_URL_FIELDS', data);
  },
  GET_URL_ENTRIES({ commit }, data) {
    commit('SET_URL_ENTRIES', data);
  },
  GET_URL_NAVIGATION({ commit }, data) {
    commit('SET_URL_NAVIGATION', data);
  },
  GET_DATAFORM({ commit }, data) {
    commit('SET_DATAFORM', data);
  },
  GET_CURRENT_COMPONENT({ commit }, data) {
    commit('SET_CURRENT_COMPONENT', data);
  },
  GET_PROPIETIER({ commit }, data) {
    commit('SET_PROPIETIER', data);
  },
  GET_WINNER_EMAIL_PREVIEW({ commit }, data) {
    commit('SET_WINNER_EMAIL_PREVIEW', data);
  },
  GET_VIEW_ENTRY({ commit }, data) {
    commit('SET_VIEW_ENTRY', data);
  },
  GET_DATA_PRIZES({ commit }, data) {
    commit('SET_DATA_PRIZES', data);
  },
  GET_CUSTOM_MEDIA_FILTER({ commit }, data) {
    commit('SET_CUSTOM_MEDIA_FILTER', data);
  },
};

const getters = {
  loading: (state) => {
    return state.loading;
  },
  pluginStatus: (state) => {
    return state.pluginStatus;
  },
  pluginConfiguration: (state) => {
    return state.pluginConfiguration;
  },
  page: (state) => {
    return state.page;
  },
  masonrypage: (state) => {
    return state.masonrypage;
  },
  displaying: (state) => {
    return state.displaying;
  },
  env: (state) => {
    return state.env;
  },
  path: (state) => {
    return state.path;
  },
  urlFields: (state) => {
    return state.urlFields;
  },
  urlEntries: (state) => {
    return state.urlEntries;
  },
  urlNavigation: (state) => {
    return state.urlNavigation;
  },
  dataform: (state) => {
    return JSON.stringify(state.dataform);
  },
  currentComponent: (state) => {
    return state.currentComponent;
  },
  propietier: (state) => {
    return state.propietier;
  },
  winnerEmailPreview: (state) => {
    return state.winnerEmailPreview;
  },
  viewEntry: (state) => {
    return state.viewEntry;
  },
  dataPrizes: (state) => state.dataPrizes,
  customFieldFilter: (state) => state.customFieldFilter,
  customMediaFilter: (state) => state.customMediaFilter,
};

export default {
  namespaced: true, state, mutations, actions, getters
};
